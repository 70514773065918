import featureImg from "./images/profitify.png";
import featureImgBlur from "./images/profitifyBlur.png";

const profitify = {
  id: "profitify",
  name: "Profitify",
  desc: "One-stop CRM platform collecting data from Shopify, Facebook Ads & Google Ads. Visualizing raw data and help customer maximum benefit.",
  featureImg,
  featureImgBlur,
  websiteLink: "https://profitify.io",
  section1: "Mobile-first experience(PWA)",
  section2: "Make MVP happen ASAP",
  section3: "Customized CRM tool",
};

export default profitify;