import featureImg from "./images/dentistLinebot.png";
import featureImgBlur from "./images/dentistLinebotBlur.png";

const dentistLinebot = {
  id: "dentistLinebot",
  name: "牙醫診所後台 & LINEBOT",
  desc: "無想牙醫小助手顛覆傳統牙科「有病治病」的觀念，更重新定義了「醫病關係」，讓醫師與患者不再只是單向的治療關係，而是經過雙向溝通後，得到滿意的療程效果。無想牙醫小助手導入 Linebot 並與病歷系統整合，有效並確實的追蹤患者狀況，透過專業的自動化服務取得病人信賴，進而提供全方位且個人化的治療。",
  featureImg,
  featureImgBlur,
  websiteLink: "https://dentist.gypin.life/accounts/sign_in",
  section1: "擬真 Google Calendar 看診日曆",
  section2: "配合 Linebot 強化使用者體驗",
  section3: "依據不同情境推播看診提醒",
};

export default dentistLinebot;