import featureImg from "./images/taqunworkshop.png";
import featureImgBlur from "./images/taqunworkshopBlur.png";

const taqunworkshop = {
  id: "taqunworkshop",
  name: "他群形象官方網站",
  desc: "他群做為台灣議題桌遊先驅，致力於透過桌遊的形式將議題遊戲化使得更多人能夠認識甚至討論議題。他群是台灣第一個實現線上桌遊擴充資源，使得玩家可以隨時體驗新的玩法。",
  featureImg,
  featureImgBlur,
  websiteLink: "https://taqunworkshop.com",
  section1: "舉辦工作坊爬梳需求",
  section2: "RWD UI 介面設計以及動態介面設計",
  section3: "設計問券開啟團隊對網站的想像",
};

export default taqunworkshop;