import React from "react";
import { withRouter } from "react-router-dom";

class PushPath extends React.Component {
  componentDidMount() {
    let path = localStorage.getItem("path");

    if (path) {
      localStorage.removeItem("path");
      this.props.history.push(path);
    } else {
      // this.props.history.push("/");
    }
  }

  render() {
    return false;
  }
}

export default withRouter(PushPath);