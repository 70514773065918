import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import { ParallaxProvider } from 'react-scroll-parallax';
import AOS from 'aos';

// Components
import Home from "./components/Home";
import WebProject from "./components/WebProject";
import AppProject from "./components/AppProject";
import PrivacyPolicy from "./components/PrivacyPolicy";

// Side effect components
import ScrollToTop from "./components/ScrollToTop.js";
import PushPath from "./components/PushPath.js";

// Animation
import { redDotAnimation } from "./animation";

class App extends React.Component {
  componentDidMount() {
    redDotAnimation();
    AOS.init();
  }

  render() {
    return (
      <ParallaxProvider>
        <Router>
          <ScrollToTop />
          <PushPath />
          <Switch>
            <Route
              path="/privacy-policy"
              component={PrivacyPolicy}
            />
            <Route
              path="/web-project/:projectId"
              component={WebProject}
            /> 
            <Route
              path="/app-project/:projectId"
              component={AppProject}
            />
            <Route
              component={Home}
            />
          </Switch>
        </Router>
      </ParallaxProvider>
    );
  }
}

export default App;
