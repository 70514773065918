import React from "react";

import "./index.css";

// images
import logo from "../../images/logo.png";
import email from "../../images/email.png";
import phone from "../../images/phone.png";
import meerkat from "../../images/meerkat.png";
import fb from "../../images/fb.png";
import medium from "../../images/medium.png";

function Footer(props) {
  const { match } = props;

  if (match.path === "/") {
    return (
      <footer id="home-footer">
        <div className="contact-info-set">
          <img src={logo} alt="logo" />
          <a href="/"><h3>無想設計</h3></a>
          <div className="contact-info">
            <h5>直接跟我們聯絡</h5>
            <div>
              <img src={email} alt="email" />
              <p>frozenfung@gmail.com</p>
            </div>
            <div>
              <img src={phone} alt="phone" />
              <p>0920-272-378</p>
              <br/>
            </div>
            <a className="link" href="https://www.facebook.com/%E7%84%A1%E6%83%B3%E8%A8%AD%E8%A8%88-228932961193125/" target="_blank" rel="noopener noreferrer"><img src={fb} alt="facebook"/></a>
            <a className="link" href="https://medium.com/%E7%84%A1%E6%83%B3%E8%A8%AD%E8%A8%88" target="_blank" rel="noopener noreferrer"><img src={medium} alt="medium"/></a>
          </div>
        </div>
        <img src={meerkat} alt="mascot" />
        <div className="contact-form">
          <h5>透過 Facebook Messagner 跟我們聊聊</h5>
          <br/>
          <button
            type="submit"
            style={{ "cursor": "pointer" }}
            onClick={() => window.FB.CustomerChat.show(true)}
          >馬上開始</button>
        </div>
      </footer>
    );
  } else {
    return (
      <footer id="web-footer">
        <div>
          <img src={logo} alt="logo" />
          <a href="/"><h6>無想設計</h6></a>
        </div>
        <p>frozenfung@gmail.com</p>
        <p>0920-272-378</p>
        <br/>
        <a className="link" href="https://www.facebook.com/%E7%84%A1%E6%83%B3%E8%A8%AD%E8%A8%88-228932961193125/" target="_blank" rel="noopener noreferrer"><img src={fb} alt="facebook" /></a>
        <a className="link" href="https://medium.com/%E7%84%A1%E6%83%B3%E8%A8%AD%E8%A8%88" target="_blank" rel="noopener noreferrer"><img src={medium} alt="medium" /></a>
      </footer>
    );
  }
}

export default Footer;