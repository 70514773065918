import featureImg from "./images/taqunsenation.png";
import featureImgBlur from "./images/taqunsenationBlur.png";

const taqunsenation = {
  id: "taqunsenation",
  name: "眾聲議會募資網站",
  desc: "聖那國是由不同文化、種族所組成的海島國家，不同的價值與期待， 曾經使國民不停對立與衝突。即使有公投，也只是比票數，而不是共和前進。每次政策公投前，透過眾聲議會的發言規則，促使傾聽與跳框思考，尋求共同點，隨機抽選公民發言， 使得大眾聲音可以不透過代議士聽見。過程全國直播，使得聲音完整不需經大眾媒體編輯，讓大眾聲音能真正被聽見與聽見。",
  featureImg,
  featureImgBlur,
  websiteLink: "https://zeus.dmmt.design",
  section1: "使用者旅程地圖工作坊",
  section2: "亜動態視覺漸變效果應用",
  section3: "知名募資公司貝殼放大技術合作",
};

export default taqunsenation;