import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ProgressiveImage from "react-progressive-bg-image";

import "./index.css";

// component
import Footer from "../Footer";

// image
import articleFakeImg from "../../images/articleFakeImg.png";
import articleFakeImgBlur from "../../images/articleFakeImgBlur.png";
import logo from "../../images/logo.png";
import * as webProjects from "../../content/webProject";

class Web extends React.Component {
  componentDidMount() {
    // console.log(this.props);
  }

  renderHelmet(name, desc, featureImg) {
    return (
      <Helmet>
        <title>無想設計  |  過往案例  |  {name}</title>
        <meta name="description" content={desc} />
        <meta property="og:image" content={featureImg} />
        <meta property="og:image:height" content="400" />
        <meta property="og:image:width" content="600" />
      </Helmet>
    );
  }

  render() {
    const { projectId } = this.props.match.params;
    // get pj info
    // console.log(projectId);
    const pj = webProjects[projectId] || webProjects["thepier"];
    // determine prev & next project
    const [prevPjId, nextPjId] = Object.keys(webProjects).filter(item => item !== pj.id)
                                        .sort(() => Math.random() - 0.5)
                                        .splice(0, 2);

    return (
      <div id="web-intro">
        {this.renderHelmet(pj.name, pj.desc, pj.featureImg)}
        <div id="white-cover"></div>
        {/* <div id="red-cover"></div> */}
        <div id="web-top">
          <a href="/">
            <img src={logo} alt="logo" />
            <h3>無想設計</h3>
          </a>
        </div>
        <div id="web-cover">
          <div data-aos="fade-up">
            <div className="jump" id="web-jump">
              <div className="jumping1"></div>
              <div className="jumping2"></div>
            </div>
            <h1>精選專案</h1>
          </div>
          <div data-aos="fade-up">
            <ProgressiveImage
              src={pj.featureImg}
              placeholder={pj.featureImgBlur}
              style={{
                height: "45vw",
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />
          </div>
          <div className="project-desc" data-aos="fade-up">
            <h3 id="name" className="fade">{pj.name}</h3>
            <p id="intro" className="fade">{pj.desc}</p>
          </div>
          <div className="button-section" data-aos="fade-up">
            <a className="web-link" href={pj.websiteLink} target="_blank" rel="noopener noreferrer">前往網站</a>
          </div>
        </div>
        <div id="web-content">
          <div data-aos="fade-up">
            <h4>專案重點 1</h4>
            <p>{pj.section1}</p>
          </div>
          <div data-aos="fade-up">
            <h4>專案重點 2</h4>
            <p>{pj.section2}</p>
          </div>
          <div data-aos="fade-up">
            <h4>專案重點 3</h4>
            <p>{pj.section3}</p>
          </div>
          <div id="web-medium" className="medium" data-aos="fade-up">
            <h4>訪談文章</h4>
            <ProgressiveImage
              src={articleFakeImg}
              placeholder={articleFakeImgBlur}
              style={{
                height: "45vw", 
                margin: "2vh 0",
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />
            <div className="fade" data-aos="fade-up">
              <h3 id="medium-title">兩廳院事件簿：烈火中的帥哥警探・攜手遊戲推廣表演藝術</h3>
            </div>
            <div data-aos="fade-up">
              <a href="https://bit.ly/2VIUFRZ" id="medium-link" target="_blank" rel="noopener noreferrer">前往訪談文章</a>
              <a href={pj.websiteLink} className="web-link" target="_blank" rel="noopener noreferrer">前往網站</a>
            </div>
          </div>
          <div className="project-links" data-aos="fade-up">
            <Link to={{
              pathname: `/web-project/${prevPjId}`
            }}>
              <div id="prev-project">
                <p id="prev-title">{webProjects[prevPjId].name}</p>
                <p>看更多專案</p>
              </div>
            </Link>
            <Link to={{
              pathname: `/web-project/${nextPjId}`
            }}>
              <div id="next-project">
                <p id="next-title">{webProjects[nextPjId].name}</p>
                <p>看更多專案</p>
              </div>
            </Link>
          </div>
        </div>
        <div id="web-contact">
          <h5>透過 Facebook Messagner 跟我們聊聊</h5>
          <br/>
          <button
            type="submit"
            style={{ "cursor": "pointer" }}
            onClick={() => window.FB.CustomerChat.show(true)}
          >馬上開始</button>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default Web;