import featureImg from "./images/southnpm.png";
import featureImgBlur from "./images/southnpmBlur.png";

const southnpm = {
  id: "southnpm",
  name: "故宮南院文史廳互動多媒體",
  desc: "協助故宮南院打造【萬象諸羅：嘉義文史展廳】展場互動多媒體體驗，以觀展的民眾體驗為主，發想並實作最能夠符合該情境下的網站設計。",
  featureImg,
  featureImgBlur,
  websiteLink: "https://south.npm.gov.tw/ExhibitionsDetailC003110.aspx?Cond=d2a3cf4a-838e-4274-a7c5-4042f8dc1df7",
  section1: "與故宮南院首度合作",
  section2: "展廳多媒體裝置設計開發",
  section3: "跨團隊整合(硬體、裝潢)達成展廳全改建",
};

export default southnpm;