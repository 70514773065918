import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link as ScrollTo, Events } from "react-scroll";
import ProgressiveImage from "react-progressive-bg-image";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

// component
import Footer from "../Footer";

import * as webProject from "../../content/webProject";
import "./index.css";

// images
import articleFakeImg from "../../images/articleFakeImg.png";
import logo from "../../images/logo.png";
import banner from "./images/banner.png";
import bannerBlur from "./images/bannerBlur.png";

// services
import workshop from "./images/workshop.gif";
import drawing from "./images/drawing.gif";
import digging from "./images/digging.gif";
import interviewing from "./images/interviewing.gif";
import servicesBottom from "./images/servicesBottom.png";

// projects
import { ReactComponent as WebZoomIn } from "./images/webZoomIn.svg";
import webZoomInInner from "./images/webZoomInInner.png";
import appZoomInInner from "./images/app.png";
import projectsBottom from "./images/projectsBottom.png";

// about
import fungProfile from "./images/fung.jpg";
import jerryProfile from "./images/jerry.png";
import gabbyGabbyAnniieProfile from "./images/gabbyGabbyAnniie.png";
import qiuProfile from "./images/qiu.png";

// price
import price1 from "./images/price1.png";
import price2 from "./images/price2.png";
import price3 from "./images/price3.png";
import price4 from "./images/price4.png";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.handleNavMenuCheckedClick = this.handleNavMenuCheckedClick.bind(this);

    const webProjectIds = Object.keys(webProject);
    this.state = {
      navMenuChecked: false,
      webProjectId: Object.keys(webProject)[Math.floor(Math.random() * webProjectIds.length)],
    };
  }

  handleNavMenuCheckedClick() {
    const { navMenuChecked } = this.state;
    // console.log(navMenuChecked);
    this.setState(() => {
      return {
        navMenuChecked: !navMenuChecked,
      };
    });
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", () => {
      window.FB.CustomerChat.hideDialog();
      this.handleNavMenuCheckedClick();
    });
  }

  renderSeeCasesButton() {
    return (
      <Link to={`web-project/${this.state.webProjectId}`}>
        <button>看過往案例</button>
      </Link>
    );
  }


  renderHelmet() {
    return (
      <Helmet>
        <title>無想設計  |  以人本設計出發，結合科技的力量，協助您打造更好的未來</title>
        <meta name="description" content="以人本設計出發，結合科技的力量，協助您打造更好的未來" />
        <meta property="og:image" content={articleFakeImg} />
        <meta property="og:image:height" content="400" />
        <meta property="og:image:width" content="600" />
      </Helmet>
    );
  }

  /* 首頁 Banner */
  renderBanner() {
    return (
      <div id="cover" data-aos="fade-up" >
        <div className="cover-desc">
          <img src={logo} alt="logo" className="cover-logo" />
          <h1>無想設計</h1>
          <h5>以人本設計出發<br/>結合科技的力量<br/>協助您打造更好的未來</h5>
          {this.renderSeeCasesButton()}
        </div>
        <div className="cover-img">
          <ProgressiveImage
            src={banner}
            placeholder={bannerBlur}
            style={{
              height: "55vw",
              backgroundSize: "contain",
              backgroundPosition: "center center",
            }}
          />
        </div>
      </div>
    );
  }
  
  renderNavBar() {
    return (
      <nav onClick={this.handleNavMenuCheckedClick}>
        <input
          type="checkbox"
          className="menu-btn"
          id="nav-checkbox"
          checked={this.state.navMenuChecked}
          onChange={() => {}}
        />
        <div className="nav-bar">
          <div>
            <div className="nav-bar-1"></div>
            <div className="nav-bar-2"></div>
          </div>
          <div className="nav-bar-3"></div>
        </div>
        <div className="nav-content">
          <ScrollTo to="services" spy={true} smooth={true} duration={500}>
            <p>服務項目</p>
          </ScrollTo>
          <ScrollTo to="projects" spy={true} smooth={true} duration={500}>
            <p>過往案例</p>
          </ScrollTo>
          <ScrollTo to="about" spy={true} smooth={true} duration={500}>
            <p>關於我們</p>
          </ScrollTo>
          <ScrollTo to="home-footer" spy={true} smooth={true} duration={500}>
            <p>聯絡我們</p>
          </ScrollTo>
        </div>
      </nav>
    );
  }

  /* 服務項目 */
  renderServices() {
    return (
      <div id="services">
        <div data-aos="fade-up" className="jump">
          <div className="jumping1"></div>
          <div className="jumping2"></div>
        </div>
        <p data-aos="fade-up" className="section-title">服務項目</p>
        <div data-aos="fade-up" className="service">
          <div data-scroll-speed="11" className="service-text" id="service-1">
            <div className="jump">
              <div className="jumping1"></div>
              <div className="jumping2"></div>
            </div>
            <h2>KICKOFF! 工作坊</h2>
            <button onClick={() => window.FB.CustomerChat.show(true)}>-> 我想申請工作坊</button>
            <p>依據每個產業不同的特性，挑選適合的設計思考方法，並應用在 KICKOFF! 工作坊。在工作坊裡面，我們將與您一起重新審視目前的產品、優化既有的工作流程以及評估未來潛在的挑戰，最後整理出可能的解決方案。</p>
          </div>
          <img src={workshop} alt="workshop" className="gif" />
        </div>
        <div data-aos="fade-up" className="service">
          <div data-aos-once="true">
            <img src={drawing} alt="design" className="gif" />
            <div className="service-text" id="service-2">
              <div className="jump">
                <div className="jumping1"></div>
                <div className="jumping2"></div>
              </div>
              <h2>介面設計</h2>
              <button onClick={() => window.FB.CustomerChat.show(true)}>-> 馬上開始設計</button>
              <p>針對使用者習慣和使用情境，規劃符合使用習慣的介面。透過使用者旅程地圖的協助，我們得以安排內容的規格以及順序，進而決定版面的配置(Wireframe)。最後，我們一起定調風格、動態元件以及介面互動方式，產出設計原型(Mockup)。</p>
            </div>
          </div>
        </div>
        <div data-aos="fade-up" className="service">
          <div className="service-text" id="service-3">
            <div className="jump">
              <div className="jumping1"></div>
              <div className="jumping2"></div>
            </div>
            <h2>系統開發與資料庫建置</h2>
            <button onClick={() => window.FB.CustomerChat.show(true)}>-> 詢問開發細節</button>
            <p>依照設計原型實作到對應的介面(Interface)，建構產品原型(Prototype)。正式釋出前，我們會依照原型規模大小決定內部測試的方式以及次數，確保系統穩定性。</p>
          </div>
          <div className="gif-div">
            <img src={digging} alt="develop" className="gif" />
          </div>
        </div>
        <div className="service" data-aos="fade-up">
          <img src={interviewing} alt="interview" className="gif"/>
          <div data-aos-anchor-placement="top-bottom" className="service-text" id="service-4">
            <div className="jump">
              <div className="jumping1"></div>
              <div className="jumping2"></div>
            </div>
            <h2>結案訪談</h2>
            <button><a href="https://bit.ly/2VIUFRZ" target="_blank" rel="noopener noreferrer">-> 看過去訪談案例</a></button>
            <p>訪談專案進行的階段過程，每位關係人在專案中扮演的角色，釐清每個人的權利與義務，並整理成專案研究以及結案報告。</p>
          </div>
        </div>
        <div className="services-bottom">
          <img src={servicesBottom} alt="bg"/>
        </div>
      </div>
    );
  }

  /* 過往案例 */
  renderProjects() {
    return (
      <div id="projects">
        <div data-aos="fade-up">
          <div className="jump">
            <div className="jumping1"></div>
            <div className="jumping2"></div>
          </div>
          <p className="section-title">過往案例</p>
        </div>

        <div className="project" data-aos="fade-up">
          <Link to={`web-project/${this.state.webProjectId}`}>
            <div className="web">
              <h4>精選網站</h4>
                <div>
                  <WebZoomIn id="web-zoom-in"/>
                  <img src={webZoomInInner} alt="websites" id="web-zoom-in-inner"/>
                </div>
              <p>我要看詳細內容 →</p>
            </div>
          </Link>
          <Link to={`web-project/${this.state.webProjectId}`}>
            <div className="web">
              <h4>企業系統</h4>
                <div>
                  <img src={appZoomInInner} alt="websites" id="web-zoom-in-inner"/>
                </div>
              <p>我要看詳細內容 →</p>
            </div>
          </Link>
        </div>
        <div className="projects-bottom" data-aos="fade-up">
          <img src={projectsBottom} alt="decoration img" />
        </div>
      </div>
    );
  }

  /* 關於我們 */
  renderAbout() {
    return (
      <div id="about">
        <div data-aos="fade-up">
          <div className="jump">
            <div className="jumping1"></div>
            <div className="jumping2"></div>
          </div>
          <p className="section-title">關於我們</p>
        </div>
        <div data-aos="fade-up" className="belief">
          <h4>執行方針</h4>
          <p>無想設計透過獨創的四步法則，致力把科技力快速導入各行各業，協助不同企業創造價值，方向包括提升產能以及加強自身產品的體驗，許多企業在我們的協助下快速實驗新業務以及達成數位轉型。</p>
        </div>
        <div data-aos="fade-up" className="blog">
          <h4>部落格</h4>
          <button><a href="https://bit.ly/2KHrA2V" target="_blank" rel="noopener noreferrer">看專案訪談</a></button>
        </div>
        <div data-aos="fade-up" className="members">
          <h4>團隊成員</h4>
          <div className="members-intro">
            <div className="member">
              <img src={fungProfile} alt="fung profile"/>
              <p>Fung</p>
              <p>專案經理</p>
            </div>
            <div className="member">
              <img src={jerryProfile} alt="jerry profile"/>
              <p>Jerry</p>
              <p>前端工程師</p>
            </div>
          </div>
          <div data-aos="fade-up" className="members-intro">
            <div className="member">
              <img src={gabbyGabbyAnniieProfile} alt="anniie profile"/>
              <p>Anniie</p>
              <p>UX / UI Designer</p>
            </div>
            <div className="member">
              <img src={qiuProfile} alt="qiu profile"/>
              <p>Mr. Qiu</p>
              <p>後端工程師</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Remove this function because of m17 project pause
  renderPrice() {
    return (
      <div id="price">
        <div data-aos="fade-up">
          <div className="jump">
            <div className="jumping1"></div>
            <div className="jumping2"></div>
          </div>
          <p className="section-title">價格方案</p>
          <Carousel
            renderThumbs={() => {}}
          >
            <img class="price-section" src={price1} alt="price" />
            <img class="price-section" src={price2} alt="price" />
            <img class="price-section" src={price3} alt="price" />
            <img class="price-section" src={price4} alt="price" />
          </Carousel>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div id="home">
        {this.renderHelmet()}
        {this.renderNavBar()}
        {this.renderBanner()}
        {this.renderServices()}
        {this.renderProjects()}
        {this.renderAbout()}
        {/* {this.renderPrice()} */}
        <Footer {...this.props} />
      </div>
    );
  }
}

export default Home;