import featureImg from "./images/blackstory.png";
import featureImgBlur from "./images/blackstoryBlur.png";

const blackstory = {
  id: "blackstory",
  name: "黑色酒吧自媒體",
  desc: "黑色酒吧是蒐集、寫作、並發表黑色題材為主軸的故事性文章平台，題材蒐羅範圍橫跨台灣、亞洲和歐美等地，冀望成為亞洲流量最大黑色題材自媒體。黑色酒吧初期將以文章為主要產品，中期結合聽覺產出影片及廣播節目，後期將加入互動元素與遊戲以及密室逃脫結合，拓展黑色題材領域應用。",
  featureImg,
  featureImgBlur,
  websiteLink: "https://blackstory.tw",
  section1: "AWS Wordpress 兩個禮拜高速開發",
  section2: "Linebot 小閻王互動推理遊戲",
  section3: "虛擬角色設計開發（IP）",
};

export default blackstory;