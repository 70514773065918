import featureImg from "./images/thepier.png";
import featureImgBlur from "./images/thepierBlur.png";

const thepier = {
  id: "thepier",
  name: "THE PIER Mobile Office 系統",
  desc: "THE PIER 辦公室將成為 21 世紀最大的教育場所，將從時尚、健康並友善的環境為起點出發，成立初衷是為台灣的空間營造及美學貢獻一份心力。THE PIER辦公室每個座位的設計及傢俱都有自己的信格，滿足對美的幻想，鼓勵大家一起在美學殿堂工作，提升效率的同時與優秀的人一起成長。",
  featureImg,
  featureImgBlur,
  websiteLink: "https://www.the-pier.com.tw/",
  section1: "無跳轉信用卡儲值體驗",
  section2: "Progressive Web App 設計",
  section3: "座位以及會議室線上預約",
};

export default thepier;