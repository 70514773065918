function redDotAnimation() {
  let jumping1s = document.getElementsByClassName('jumping1');
  let jumping2s = document.getElementsByClassName('jumping2');

  for(let i = 0; i < jumping1s.length; i++) {
    jumping1s[i].animate([
      // keyframes
      { opacity: '1' },
      { opacity: '0.1' }
    ], {
      // timing options
      duration: 2000,
      iterations: Infinity
    });
    jumping1s[i].animate([
    // keyframes
      { transform: 'scale(0.4)' },
      { transform: 'scale(2)' }
    ], {
      // timing options
      duration: 2000,
      iterations: Infinity
    });
    jumping2s[i].animate([
    // keyframes
      { opacity: '1' },
      { opacity: '0.1' }
    ], {
      // timing options
      duration: 2000,
      delay: 1000,
      iterations: Infinity
    });
    jumping2s[i].animate([
    // keyframes
      { transform: 'scale(0.4)' },
      { transform: 'scale(2)' }
    ], {
      // timing options
      duration: 2000,
      delay: 1000,
      iterations: Infinity
    });
  }
}

export {
  redDotAnimation
};