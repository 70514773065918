import featureImg from "./images/judicialintern.png";
import featureImgBlur from "./images/judicialinternBlur.png";

const judicialintern = {
  id: "judicialintern",
  name: "實習判官 - 全球首創線上密室逃脫",
  desc: "冥界是所有亡魂都會來的世界，亡魂在這裡經過審判跟清理生前的債務之後，就會重新投胎或前往其他世界。好人有機會進入極樂世界，壞人會下地獄，小善小惡的一般人則會把生前記憶跟遺憾解決之後，就重新投胎。",
  featureImg,
  featureImgBlur,
  websiteLink: "https://judicial-intern.club/",
  section1: "不用下載任何 APP，創建 LINE 群組即可開始推理 🎮",
  section2: "多人協力推理，難解的懸案不再一個人面對 🔍",
  section3: "MIT 台灣自製劇本，各種意想不到的情節讓你腦洞大開 🤯",
};

export default judicialintern;