import featureImg from "./images/clubon.png";
import featureImgBlur from "./images/clubonBlur.png";

const clubon = {
  id: "clubon",
  name: "聚樂邦實境遊戲",
  desc: "聚樂邦是一間推廣玩樂的設計公司，將遊戲化應用於社會議題的探討與實踐，期望引動青年一起發揮創意，協力打造自他共榮的社會環境。聚樂邦把遊戲帶入議題、帶入空間和社區營造的場域，還是遊戲化的教育訓練，甚至是遊戲化的展覽策劃，聚樂邦都能夠提供完整的規劃和設計。",
  featureImg,
  featureImgBlur,
  websiteLink: "https://www.clubon.space/",
  section1: "進十款室內及戶外不同場景實境",
  section2: "多人組隊同步遊戲系統",
  section3: "遊戲後台數據觀測",
};

export default clubon;